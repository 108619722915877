<template>
  <div class="account-containers">
    <div class="wrapper" >
      <div class="account-container">
        <div class="aside">
          <a-menu accordion auto-open-selected :selected-keys="selectedKey" :style="{ width: '100%', height: 'auto', minHeight: '500px', background: '#fff', borderRadius: '2px' }" >
            <!--  -->
            <div v-for="(item, index) in menu" :key="index">
              <a-sub-menu v-if="item.children && item.children.length > 0">
                <template #icon><icon-folder /></template>
                <template #title>{{item.lable}}</template>
                <a-menu-item v-for="(items) in item.children" :key="items.path" @click="handleNav(items)">{{items.lable}}</a-menu-item>
              </a-sub-menu >
              <a-menu-item :key="item.path" @click="handleNav(item)" v-else>
                <template #icon>
                  <icon-user v-if="item.path == '/account/center'" />
                  <icon-book v-if="item.path == '/account/course'" />
                  <icon-notification v-if="item.path == '/account/notification'" />
                </template>
                {{item.lable}}
              </a-menu-item>
            </div>
          </a-menu>
        </div>
        <div class="main">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 数据响应  定义数据
// ref                 定义基本数据类型
// reactive            定义一个复杂数据类型
// toRefs              将reactive()创建的对象 解构赋值
import { useRouter } from 'vue-router'
import { reactive, toRefs, watch, h, compile } from 'vue'

export default {
  setup() {
    const router = useRouter()
    const state = reactive({
      menu: [
        { path: '/account/center', lable: '个人中心', children: [] },
        { path: '', lable: '我的资料',  children: [
          { path: '/account/info', lable: '个人信息' },
          { path: '/account/setting', lable: '安全设置' },
          // { path: '/account/invoice', lable: '发票' },
        ] },
        { path: '/account/course', lable: '我的课程',  children: [] },
        { path: '/account/notification', lable: '消息通知', children: [] },
        // { path: '/account/footprint', lable: '我的足迹', children: [] },
        // { path: '/account/collect', lable: '我的收藏', children: [] },
        // { path: '/account/certificate', lable: '我的证书', children: [] },
      ],
      selectedKey: '',
    })
    if (!window.localStorage.getItem('webtoken')) {
      router.replace({path: '/login'})
    }
    watch(() => router.currentRoute.value.path, (newPath, oldPath) => {
      state.selectedKey = [newPath]
    }, { immediate: true })
    const handleNav = (item) => {
      router.push({ path: item.path })
    }
    return {
      ...toRefs(state),
      handleNav
    }
  }
}
</script>
<style>
.arco-menu-vertical .arco-menu-inner {
  padding: 0;
  padding-top: 10px;
}
.arco-menu-light .arco-menu-inline-header {
  border-left: 4px solid transparent;
  border-radius: 0;
}
.arco-menu-light .arco-menu-inline-header:hover {
  background-color: rgba(32, 122, 255, .1);
}
.arco-menu-light .arco-menu-inline-header.arco-menu-selected {
  color: #4F17A8;
}
.arco-menu-inline-content .arco-menu-item {
  padding-left: 22px;
}
.arco-menu-light .arco-menu-inline-header.arco-menu-selected .arco-icon {
  color: #4F17A8 !important;
}
/* 
.arco-menu-inner::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}
.arco-menu-inner::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 7px;
  background-color: rgb(201,205,212);
} */
</style>
<style lang="less" scoped>
.arco-menu-item {
  border-left: 4px solid transparent;
  border-radius: 0;
}
.arco-menu-item:hover {
  background: rgba(147, 32, 255, 0.1);

}
.arco-menu-light .arco-menu-item.arco-menu-selected {
  border-left: 4px solid #4F17A8;
  color: #4F17A8;
  background: rgba(147, 32, 255, 0.1);

}
.arco-menu-light .arco-menu-item.arco-menu-selected .arco-icon {
  color: #4F17A8 !important;
}

.account-containers {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background: #F7F6FA;
  // background-color: antiquewhite;
  min-height: calc(100vh - 132px);
}
.account-container {
  display: flex;
  align-items: flex-start;
}
.aside {
  width: 220px;
  height: 500px;
  overflow: hidden;
  margin-right: 14px;
}

.main {
  flex: 966px;
  overflow: hidden;
  // height: calc(100vh - 20px);
  // height: 1000px;
}

</style>
